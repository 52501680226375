#inputFieldContainer {
    width: 100%;
    /* background-color: #f6f6f8; */
    padding-top: 15px;
}

#inputFieldHeader {
    font-size: 22px;
    margin-left: 9%;
}

#nqBalanceTable {
    margin-left: 9%;
}

.searchButton {
    margin-top: 10px;
}

.inputFieldText {
    width: 400px;
    padding-right: 20px;
    font-size: 17px;
    margin-right: 20px;
}

.inputField {
    width: 400px;
    padding-right: 20px;
    font-size: 17px;
    margin-bottom: 4em;
    margin-right: 20px;
}

.inputFieldTextRequired:after {
    content: " *";
    color: red;
    width: 400px;
    padding-right: 20px;
    font-size: 17px;
    margin-right: 20px;
}

.inputContract {
    height: 60px !important;
}

.planDropDownSelect {
    z-index: 5 !important;
}

#filterHeader {
    font-size: 20px;
    font-weight: bold;
}

#datePicker {
    border-radius: 5px;
}

#resetSearch {
    color: #0061A0;
}

#resetSearch:hover {
    cursor: pointer;
}

#submitRow {
    margin-top: 1em;
    padding-bottom: 18px;
}

.reactMultiSelect {
    margin-bottom: 37px;
    height: 5px;
}

.input {
    height: 65px !important;
}

#loading {
    width: 10rem;
    height: 10rem;
    position: fixed;
    left: 45%;
    top: 20%;
    right: 50%;
    bottom: 50%;
}

.blur {
    filter: blur(5px);
}